<template>
  <div class="cart-empty">
    <h1 v-html="$t('terminal.cart.cart.empty')"></h1>
    <div class="description" v-html="description"></div>
    <div class="image">
      <img :src="iconProductScan" />
    </div>
  </div>
</template>

<script>
import ICONS from '../../utils/icons';

const {
  iIndexProductScan,
} = ICONS;

export default {
  name: 'cart-empty',
  props: {
    categories: {
      type: Array,
    },
  },
  data() {
    return {
      iconProductScan: iIndexProductScan.greenUrl,
    };
  },
  computed: {
    description() {
      if (this.categories && this.categories.length > 0) {
        return this.$t('terminal.cart.cart.emptyDescription');
      }
      return this.$t('terminal.cart.cart.emptyDescription')
        .substring(0, this.$t('terminal.cart.cart.emptyDescription').indexOf('<br>'));
    },
  },
};
</script>
